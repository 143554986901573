

















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OCompanyRegistrationFormList: () =>
      import(
        "@/components/organisms/globalTools/companyRegistrationForm/o-company-registration-form-list.vue"
      ),
  },

  setup() {
    return {};
  },
});
